$(function(){

  const className = '.banner-carousel';
  const mediaQuery = window.matchMedia( "(max-width: 759px)" );

  //mediaQuery.addListener( changeSlick );

  $(className).slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 800,
    mobileFirst: true,
    prevArrow: '<img src="/images/carousel/prev_arrows.svg" class="slide-arrow prev-arrow">',
    nextArrow: '<img src="/images/carousel/next_arrows.svg" class="slide-arrow next-arrow">',
    //rows: 2,
    //slidesPerRow: 1,
    responsive: [
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      }
    ]
  });




});
